import React from "react";
import { Link } from "gatsby";

import Card from "../PageComponents/Card";
import Button from "../PageComponents/Button";

export const WhatWeDoCards = ({ actions }) => {
  return (
    <div className="flex flex-wrap space-y-10 sm:space-y-12 lg:space-y-0 lg:-mx-2">
      {actions.map((action, index) => {
        const { title, copy, link, image } = action;
        return (
          <div className="w-full lg:w-1/3 lg:px-2">
            <Card
              key={index}
              title={title}
              copy={copy}
              link={link}
              image={image}
            />
          </div>
        );
      })}
    </div>
  );
};

const WhatWeDo = ({ data }) => {
  const { title, actions } = data;

  return (
    <section className="bg-offWhite py-16 md:py-20 xl:py-32">
      <div className="container">
        <h2 className="text-center text-4xl sm:text-5xl lg:text-6xl">
          {title}
        </h2>
        <div className="mt-16 lg:mt-24">
          <WhatWeDoCards actions={actions} />
        </div>
        <div className="mt-16 md:mt-20">
          <h5 className="text-center text-xl sm:text-3xl lg:text-4xl">
            We’ve reunited over 600 families.
          </h5>
          <div className="flex justify-center lg:mt-8">
            <Link to={`/donate/`}>
              <Button style="dark">Learn More</Button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeDo;
