import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { styled } from "twin.macro";
import Context from "../../context/Context";
import FeedbackModal from "./Modal";
import GFForm from '../PageComponents/Forms/GFForm';

const NewsletterWrapper = styled.section`
  order: 0;
  background-color: #fff;
  width: 100%;

  @media (min-width: 768px) {
    order: 1;
  }

  @media (min-width: 1200px) {
    order: 2;
    width: 100%;
    min-width: 500px;
    // max-width: 700px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 1000px var(--color-magenta) inset !important;
    -webkit-text-fill-color: #fff;
  }

  form {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }

  form > *:not([type="submit"]) {
    padding: 0 0.5rem;
    margin-top: 1rem;
  }

  form > * {
    margin-bottom: 1rem;
  }

  form .submit-button {
    width: auto;
  }

  form .first-name-field,
  form .last-name-field,
  form .address-field,
  form .phone-field {
    width: 50%;
  }

  form .zip-address-field,
  form .email-field { width: 100%; }

  @media (min-width: 768px) {
    form .submit-button {
      margin-top: 2rem;
    }
  }

  @media (min-width: 1024px) {
    form .first-name-field,
    form .last-name-field {
      margin-top: 0;
    }
  }
`;

const NewsletterCopy = styled.div`
  text-align: center;
  padding: 40px 20px 20px 0;

  p {
    max-width: 20rem;
    margin: 0 auto;
  }

  @media (min-width: 768px) {
    text-align: left;

    p { max-width: 100%; }
  }

  @media (min-width: 1024px) {
    padding: 0 56px;
  }
`;

const NewsletterComponent = () => {
  const { allGfForm } = useStaticQuery(graphql`
    {
      allGfForm(filter: {formId: { eq: 1 } }) {
        edges {
          node {
            formId
            title
            description
            slug
            apiURL
            confirmations {
              id
              name
              isDefault
              type
              message
              url
              queryString
            }
            formFields {
              type
              label
              placeholder
              cssClass
              id
              inputs {
                name
                label
                inputType
                choices {
                  text
                  value
                }
                isHidden
                id
                placeholder
              }
              isRequired
              addressType
              checkboxLabel
              choices
              conditionalLogic
              defaultValue
            }
          }
        }
      }
    }
  `);

  const { setModal } = useContext(Context);

  return (
    <NewsletterWrapper className="py-10 lg:py-24">
      <div className="container mx-auto">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 xl:pr-5">
            <NewsletterCopy>
              <h3>Add Impact To Your Inbox</h3>
              <p className="leading-normal">Learn about timely petitions, children who need immediate support, immigration policy updates, and more.</p>
            </NewsletterCopy>
          </div>
          <div className="w-full md:w-1/2 xl:w-5/12 xl:mr-auto xl:pl-5">
            <div className="max-w-md mx-auto md:max-w-full">
              <GFForm
                id={1}
                data={allGfForm.edges[0].node}
                submitText={'Sign Up'}
                clearOnSuccess={true}
              />
            </div>
          </div>
        </div>
      </div>
    </NewsletterWrapper>
  );
};
export default NewsletterComponent;
