import React from "react";
import { styled } from "twin.macro";

const Partners = styled.section`
  background-color: var(--color-offWhite);
  padding: 60px 45px;
  text-align: center;

  @media (min-width: 768px) {
    padding: 90px 64px;
  }

  @media (min-width: 1024px) {
    padding: 120px;
  }
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: -0.63px;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -1.01px;
    margin-bottom: 60px;
  }

  @media (min-width: 1024px) {
    font-size: 43px;
    line-height: 52px;
    letter-spacing: -1.09px;
  }
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (min-width: 1024px) {
    max-width: 1062px;
    margin: 0 auto;
  }
`;

const Partner = styled.li`
  display: flex;
  justify-content: center;
  width: 33.33%;
  text-align: center;

  &:nth-of-type(1),
  &:nth-of-type(2),
  &:nth-of-type(3) {
    margin-bottom: 30px;
  }

  @media (min-width: 768px) {
    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3) {
      margin-bottom: 60px;
    }
  }

  @media (min-width: 1024px) {
    width: auto;

    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3) {
      margin-bottom: 0;
    }
  }
`;

const Logo = styled.img`
  width: 100%;
  max-width: 70px;

  @media (min-width: 768px) {
    max-width: 105px;
  }

  @media (min-width: 1024px) {
    max-width: 125px;
  }
`;

const OurPartners = ({ partners }) => {
  return (
    <Partners>
      <Title>Our Partners</Title>
      {partners?.length && (
        <List>
          {partners.map((item, index) => (
            <Partner key={index}>
              <a href={item.url} target="_blank" rel="noreferrer">
                <Logo src={item.logo.source_url} alt={item.client_name} />
              </a>
            </Partner>
          ))}
        </List>
      )}
    </Partners>
  );
};

export default OurPartners;
