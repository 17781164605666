import React, { useState } from "react";
import tw, { styled } from "twin.macro";
import ReactPlayer from "react-player/vimeo";
import { ReactComponent as PlayIcon } from "../../images/BUTTON_PLAY.svg";
import { ReactComponent as PauseIcon } from "../../images/BUTTON_PAUSE.svg";
import FallBack from "../../images/hero_fallback.png";

const FullVideoWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vw;
  position: relative;
  background-size: cover;

  @media (min-width: 768px) {
    height: auto;
  }

  .video {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    width: 100% !important;
    height: auto !important;

    &--playing {
      z-index: 10;
    }
  }

  .video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
`;

const ControlsAndTitle = styled.div`
  position: absolute;
  border: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  text-align: center;
  transition: opacity 2s ease;

  &.hidden {
    opacity: 0;
    z-index: -1;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const Poster = styled.div`
  background-image: ${props =>
    props.background ? `url(${props.background})` : `url(${FallBack})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity 0.5s ease;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &.hidden {
    opacity: 0;
    z-index: -1;
  }
`;

const PlayButton = styled.button`
  border-radius: 50%;
  padding: 10px;
  background-color: var(--color-offWhite);
  height: 50px;
  width: 50px;
  z-index: 2;
  margin: 0 auto;
  border: none;
  cursor: pointer;
  opacity: 1;
  transition: opacity 1s ease;
  margin-bottom: 16px;
  text-align: center;

  &.hidden {
    opacity: 0;
  }

  &:focus {
    outline: 1px solid transparent;
  }

  svg {
    fill: var(--color-magenta);
    width: 20.41px;
    height: 19.95px;
    padding-left: 2px;
    margin: 0 auto;
  }

  @media (min-width: 768px) {
    height: 80px;
    width: 80px;
    margin-bottom: 24px;
    padding: 24px;
    svg {
      width: 31.43px;
      height: 30.72px;
    }
  }
`;

const VideoTitle = styled.p`
  text-transform: uppercase;
  font-weight: 600;
  color: var(--color-offWhite);
  opacity: 1;
  transition: opacity 1s ease;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.12px;

  &.hidden {
    opacity: 0;
  }

  @media (min-width: 768px) {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.16px;
  }
  @media (min-width: 1024px) {
    margin: 16px;
    text-align: left;
  }
`;
const Time = styled.span`
  font-family: attribute-mono, monospace;
  font-weight: normal;
  display: block;
  margin-top: 2px;
`;

const FullVideoComponent = ({ title, video, poster }) => {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [duration, setDuration] = useState(" ");

  const handleDuration = duration => {
    const minutes = (duration / 60).toFixed(0);
    const seconds = duration % 60;
    setDuration(minutes.length > 0 ? `${minutes}:${seconds}` : seconds);
  };

  return (
    <FullVideoWrapper>
      <ReactPlayer
        url={video || "https://vimeo.com/447558161/92927af086"}
        playing={videoPlaying}
        loop={false}
        muted={false}
        controls={true}
        playsinline={true}
        onDuration={handleDuration}
        className={`video${videoPlaying ? " video--playing" : ""}`}
      />
      <Poster className={videoPlaying ? "hidden" : ""} background={poster} />
      <ControlsAndTitle className={videoPlaying ? "hidden" : ""}>
        <PlayButton
          onClick={() => {
            setVideoPlaying(true);
          }}
          className={videoPlaying ? "hidden" : ""}
        >
          {videoPlaying ? <PauseIcon /> : <PlayIcon />}
        </PlayButton>
        <VideoTitle className={videoPlaying ? "hidden" : ""}>
          {title}
          <Time>{duration}</Time>
        </VideoTitle>
      </ControlsAndTitle>
    </FullVideoWrapper>
  );
};

export default FullVideoComponent;
